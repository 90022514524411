import React from 'react'
import { Link } from 'gatsby'
import styled from '@emotion/styled'
import PageTitle from '../components/PageTitle'
import Container from '../components/Container'
import Layout from '../components/Layout'
import SEO from '../components/SEO'
import ContainerHero from '../components/ContainerHero'
import Subheadline from '../components/Subheadline'

const ContentContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  h1 {
    padding: 30px 15px;
    border-bottom: 2px solid #000;
    @media (min-width: ${props => props.theme.responsive.medium}) {
      padding: 30px;
    }
  }
`

const NotFoundPage = () => (
  <Layout>
    <SEO title="404" description="Seite nicht gefunden." />
    <Container>
      <ContainerHero>
        <ContentContainer>
          <PageTitle>ERRRRR... 404.04404004040</PageTitle>
          <Subheadline>
            Hier gibt es nichts zu sehen. Wirklich nicht. Bitte weitergehen.{' '}
            <br />→ Schnell zur <Link to="/">Startseite</Link> zurückkehren und
            einen neuen Weg versuchen.
          </Subheadline>
        </ContentContainer>
      </ContainerHero>
    </Container>
  </Layout>
)

export default NotFoundPage
